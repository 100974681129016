exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-atelier-jsx": () => import("./../../../src/pages/atelier.jsx" /* webpackChunkName: "component---src-pages-atelier-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-day-charter-index-jsx": () => import("./../../../src/pages/day-charter/index.jsx" /* webpackChunkName: "component---src-pages-day-charter-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-efoil-jsx": () => import("./../../../src/pages/locations/efoil.jsx" /* webpackChunkName: "component---src-pages-locations-efoil-jsx" */),
  "component---src-pages-locations-esurf-jsx": () => import("./../../../src/pages/locations/esurf.jsx" /* webpackChunkName: "component---src-pages-locations-esurf-jsx" */),
  "component---src-pages-locations-index-jsx": () => import("./../../../src/pages/locations/index.jsx" /* webpackChunkName: "component---src-pages-locations-index-jsx" */),
  "component---src-pages-locations-seabob-jsx": () => import("./../../../src/pages/locations/seabob.jsx" /* webpackChunkName: "component---src-pages-locations-seabob-jsx" */),
  "component---src-pages-vente-jsx": () => import("./../../../src/pages/vente.jsx" /* webpackChunkName: "component---src-pages-vente-jsx" */)
}

